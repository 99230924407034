import { useActiveProfileSubscriptions } from 'utils/hooks/useAuthenticatedUser';
import {
  SUBSCRIPTIONS_STATUSES,
  SUBSCRIPTIONS_SLUG,
} from 'containers/UserContainers/AccountSettingsContainer/constants';

export function useSubscription() {
  const activeProfileSubscriptions = useActiveProfileSubscriptions();

  const isActiveSubscription = item =>
    [SUBSCRIPTIONS_STATUSES.active, SUBSCRIPTIONS_STATUSES.nonRenewing, SUBSCRIPTIONS_STATUSES.inTrial].includes(
      item.status,
    );
  const activeSubscriptions = activeProfileSubscriptions?.filter(isActiveSubscription);

  const subscription =
    activeSubscriptions?.length > 1
      ? activeProfileSubscriptions.find(
          item => isActiveSubscription(item) && item?.subscription?.slug !== SUBSCRIPTIONS_SLUG.ArtistFree,
        )
      : activeSubscriptions?.[0] || activeProfileSubscriptions?.[0];

  return subscription;
}
